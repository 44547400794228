// src/components/MainLayout.tsx
import React, { useState, useEffect } from 'react';
import mermaid from 'mermaid';
import ChatBox from './ChatBox'; // Import the existing ChatBox component
import FileUpload from './FileUpload'; // Import the existing FileUpload component
import TCModal from './TCModal';


const MainLayout: React.FC = () => {
  // Default Mermaid diagram
  const defaultDiagram = `
    graph TD;
    A[Start] --> B{Is it sunny?};
    B -->|Yes| C[Go outside];
    B -->|No| D[Stay inside];
    C --> E[Enjoy the day];
    D --> E;
  `;

  const [fileContent, setFileContent] = useState<string>(defaultDiagram); // Default diagram content
  const [showModal, setShowModal] = useState<boolean>(true); // Modal visibility state

  // Callback to pass to ChatBox to update the file content
  const handleFileContentUpdate = (content: string) => {
    setFileContent(content);
  };

  // Initialize Mermaid.js whenever the file content changes
  useEffect(() => {
    // Initialize Mermaid diagram rendering
    mermaid.initialize({ startOnLoad: true });
    mermaid.contentLoaded();
  }, [fileContent]);

  // Handle accepting the T&C
  const handleAccept = () => {
    setShowModal(false);
    localStorage.setItem('acceptedTnC', 'true'); // Store acceptance in localStorage
  };

  // Handle rejecting the T&C (could redirect or disable the app)
  const handleReject = () => {
    alert('You must accept the T&C to use this application.');
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Show modal if the user hasn't accepted the T&C */}
      {showModal && <TCModal onAccept={handleAccept} onReject={handleReject} />}
      {/* Navigation Bar */}
      <header className="bg-zinc-600 text-white shadow-lg">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <img src="/calogo.png" alt="App Logo" className="h-10 w-30 mr-4" />
            <h1 className="text-xl font-bold">Verwaltung der Zukunft - Process Automator</h1>
          </div>
          
          {/* Burger Menu for small screens */}
          <div className="md:hidden">
            <button className="focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>

          {/* Navigation Links */}
          {/* <nav className="hidden md:flex space-x-6">
            <a href="#" className="hover:underline">Home</a>
            <a href="#" className="hover:underline">About</a>
            <a href="#" className="hover:underline">Contact</a>
          </nav> */}
        </div>
      </header>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-[80%_20%] gap-8">
        {/* Left Column - File Content and ChatBox */}
        <div className="space-y-8">
          {/* File Content Display */}
          <div className="bg-white p-6 shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-center">Process Diagram View</h2>
            <div className="border border-gray-300 rounded-lg bg-gray-100 p-4 h-96 overflow-y-auto">
              {/* Display Mermaid diagram if file content is present */}
              {fileContent ? (
                <div className="mermaid">
                  {fileContent}
                </div>
              ) : (
                <p className="text-gray-500">File content will appear here...</p>
              )}
            </div>
          </div>

          {/* ChatBox - Below File Content */}
          <div className="bg-white p-6 shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-center">Process Description</h2>
            <ChatBox onFileContentUpdate={handleFileContentUpdate} />
          </div>
        </div>

        {/* Right Column - Configurations */}
        <div className="space-y-8">
          {/* File Upload */}
          <div className="bg-white p-6 shadow-md rounded-lg">
            <FileUpload />
          </div>

          {/* Cloud Provider Selection */}
          <div className="bg-white p-6 shadow-md rounded-lg">
            <h3 className="text-xl font-bold mb-4">Select Infrastructure Provider</h3>
            <select className="w-full border border-gray-300 rounded-lg p-2">
              <option>AWS</option>
              <option>Azure</option>
              <option>Google Cloud</option>
              <option>IBM Cloud</option>
            </select>
          </div>

          <div className="bg-white p-6 shadow-md rounded-lg">
            <h3 className="text-xl font-bold mb-4">Select AI Provider</h3>
            <select className="w-full border border-gray-300 rounded-lg p-2">
              <option>CloudAhead</option>
              <option>PD</option>
            </select>
          </div>

        </div>
      </div>
    </div>
  );
};

export default MainLayout;