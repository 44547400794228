// src/components/ChatBox.tsx
import React, { useState } from 'react';
import axios from 'axios';


// Define the type for the props
interface ChatBoxProps {
  onFileContentUpdate: (content: string) => void;
}

const ChatBox: React.FC<ChatBoxProps> = ({ onFileContentUpdate }) => {
  const [query, setQuery] = useState<string>(''); // User input
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state

  // Handle textarea input
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setQuery(event.target.value);
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    if (!query.trim()) return;

    setIsLoading(true);
    
    try {
      // Replace this with actual OpenAI or server interaction

      const response = await axios.post('http://localhost:9999/api/chat/completions', {
        query: query,
      });
      
      // Call the prop function to update the file content in the parent
      onFileContentUpdate(response.data.choices[0].text);
    } catch (error) {
      console.error('Error fetching file content:', error);
      onFileContentUpdate('Error retrieving file content.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* Input form */}
      <form onSubmit={handleSubmit} className="space-y-4">
        <textarea
          value={query}
          onChange={handleInputChange}
          rows={5}
          placeholder="Enter your query..."
          className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          disabled={isLoading}
        ></textarea>
        <button
          type="submit"
          disabled={isLoading || !query.trim()}
          className={`w-full py-2 px-4 rounded-md text-white font-semibold 
          ${isLoading || !query.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'} 
          focus:outline-none focus:ring-2 focus:ring-blue-500 transition ease-in-out duration-150`}
        >
          {isLoading ? 'Loading...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default ChatBox;