// src/components/Modal.tsx
import React from 'react';

interface TCModalProps {
  onAccept: () => void;
  onReject: () => void;
}

const TCModal: React.FC<TCModalProps> = ({ onAccept, onReject }) => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4">Terms & Conditions</h2>
        <p className="text-gray-700 mb-4">
          By using this application, you agree to our Terms & Conditions. Please review them carefully.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onReject}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Reject
          </button>
          <button
            onClick={onAccept}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default TCModal;