// src/components/FileUpload.tsx
import React, { useState } from 'react';
import AWS from 'aws-sdk';
import awsConfig from '../awsConfig';

const FileUpload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>(''); // To store upload status
  const [isLoading, setIsLoading] = useState<boolean>(false);   // To manage loading state

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  // Handle file upload
  const handleFileUpload = async (): Promise<void> => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }

    setIsLoading(true);

    // Configure AWS S3
    AWS.config.update({
      accessKeyId: awsConfig.accessKeyId,
      secretAccessKey: awsConfig.secretAccessKey,
      region: awsConfig.region,
    });

    const s3 = new AWS.S3();
    const params = {
      Bucket: awsConfig.bucketName,
      Key: selectedFile.name,
      Body: selectedFile,
      ContentType: selectedFile.type,
    };

    try {
      const data = await s3.upload(params).promise();
      setUploadStatus('File uploaded successfully');
      console.log('Upload data:', data);
    } catch (error) {
      setUploadStatus('Error uploading file');
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Upload File to S3</h2>
      
      {/* File Input */}
      <div className="mb-4">
        <input
          type="file"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
        />
      </div>
      
      {/* Upload Button */}
      <button
        onClick={handleFileUpload}
        disabled={isLoading || !selectedFile}
        className={`w-full py-2 px-4 rounded-md text-white font-semibold 
          ${isLoading || !selectedFile ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'} 
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition ease-in-out duration-150`}
      >
        {isLoading ? 'Uploading...' : 'Upload'}
      </button>

      {/* Upload Status */}
      {uploadStatus && (
        <p className={`mt-4 text-center font-semibold ${uploadStatus.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>
          {uploadStatus}
        </p>
      )}
    </div>
  );
};

export default FileUpload;